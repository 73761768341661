import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import SwiperSlider, { SwiperSlide } from "@ui/swiper";
import Image from "@ui/image";
import {
  HeadingType,
  ButtonType,
  ImageType,
  TextType,
  VideoButtonType,
} from "@utils/types";
import { HeroWrapper } from "./style";

const sliderSettings = {
  slidesPerView: 1,
  loop: true,
  speed: 1000,
  pagination: false,
  navigation: true,
  autoplay: true,
  delay: 27000,
};

const HeroArea = () => {
  const heroBanners = useStaticQuery(graphql`
    query heroThreeQuery {
      ImageOne: file(relativePath: { eq: "banner-1.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, width: 1920)
        }
      }
      ImageTwo: file(relativePath: { eq: "banner-2.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, width: 1920)
        }
      }
      ImageThree: file(relativePath: { eq: "banner-3.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, width: 1920)
        }
      }
      ImageFour: file(relativePath: { eq: "banner-4.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, width: 1920)
        }
      }
      ImageFive: file(relativePath: { eq: "banner-5.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, width: 1920)
        }
      }
    }
  `);
  const { ImageOne, ImageTwo, ImageThree, ImageFour, ImageFive } = heroBanners;
  return (
    <HeroWrapper>
      <SwiperSlider options={sliderSettings} paginationTop="80px">
        <SwiperSlide className="item">
          <Image src={ImageOne} alt="Slider" />
        </SwiperSlide>
        <SwiperSlide className="item">
          <Image src={ImageTwo} alt="Slider" />
        </SwiperSlide>
        <SwiperSlide className="item">
          <Image src={ImageThree} alt="Slider" />
        </SwiperSlide>
        <SwiperSlide className="item">
          <Image src={ImageFour} alt="Slider" />
        </SwiperSlide>
        <SwiperSlide className="item">
          <Image src={ImageFive} alt="Slider" />
        </SwiperSlide>
      </SwiperSlider>
    </HeroWrapper>
  );
};

HeroArea.propTypes = {
  data: PropTypes.shape({
    headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
    buttons: PropTypes.arrayOf(PropTypes.shape(ButtonType)),
    images: PropTypes.arrayOf(PropTypes.shape(ImageType)),
    texts: PropTypes.arrayOf(PropTypes.shape(TextType)),
    video_button: PropTypes.shape(VideoButtonType),
  }),
};

export default HeroArea;
