import styled, { themeGet } from "@styled";

export const HeroWrapper = styled.section`
  overflow: hidden;
  .swiper-wrapper {
    margin-bottom: 0;
  }
  .swiper-pagination {
    bottom: 0;
    margin-top: 0;
  }
  .item {
    img {
      border-radius: ${themeGet("radii.md")};
    }
  }
`;
