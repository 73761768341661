import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col } from "@ui/wrapper";
import SectionTitle from "@ui/section-title";
import Button from "@ui/button";
import ServiceBox from "@components/box-image/layout-02";
import { SectionWrap, SectionBottom } from "./style";

const ServiciosArea = () => {
  const data = useStaticQuery(graphql`
    {
      toldos: file(relativePath: { eq: "icons/toldos.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      sonido: file(relativePath: { eq: "icons/sonido.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      luces: file(relativePath: { eq: "icons/luces.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      pantallas: file(relativePath: { eq: "icons/pantallas.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      barras: file(relativePath: { eq: "icons/barras.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      horaloca: file(relativePath: { eq: "icons/hora-loca.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  const servicios = [
    {
      id: "1",
      nombre: "Toldos",
      icono: data.toldos.childImageSharp,
      excerpt: "Los mejores toldos",
    },
    {
      id: "2",
      nombre: "Sonido",
      icono: data.sonido.childImageSharp.gatsbyImageData,
      excerpt: "Los mejores toldos",
    },
    {
      id: "3",
      nombre: "Luces",
      icono: data.luces.childImageSharp.gatsbyImageData,
      excerpt: "Los mejores toldos",
    },
    {
      id: "4",
      nombre: "Pantallas",
      icono: data.pantallas.childImageSharp.gatsbyImageData,
      excerpt: "Los mejores toldos",
    },
    {
      id: "5",
      nombre: "Barras",
      icono: data.barras.childImageSharp.gatsbyImageData,
      excerpt: "Los mejores toldos",
    },
    {
      id: "6",
      nombre: "Hora Loca",
      icono: data.horaloca.childImageSharp.gatsbyImageData,
      excerpt: "Los mejores toldos",
    },
  ];
  return (
    <SectionWrap>
      <Container>
        <SectionTitle
          color="#fff"
          title="EVENTOS SEGUROS DE COVID"
          subtitle="Podemos ayudarlo con sus próximos dispositivos de aumento, transmisor FM, micrófonos desinfectados, llame para discutir. Transmite 200 'a las audiencias de la radio FM del automóvil, para que todos estén seguros."
        />
        <Row>
          {servicios.map(({ id, nombre, icono, excerpt }) => (
            <Col lg={4} md={6} className="box-item" key={`servicio-${id}`}>
              <ServiceBox
                title={nombre}
                desc={excerpt}
                image={icono}
                path={`/servicios}`}
              />
            </Col>
          ))}
        </Row>
        <SectionBottom>
          <Button path="/servicios" m="10px">
            Sonido en vivo, iluminación, medios y producción para eventos
            corporativos, presentaciones de bandas, exhibiciones, conciertos al
            aire libre, música de adoración. ​Sirviendo en Lima y más durante
            más de 15 años.
          </Button>
        </SectionBottom>
      </Container>
    </SectionWrap>
  );
};

export default ServiciosArea;
