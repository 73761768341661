import * as React from "react";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-03";
import HeroArea from "@containers/index/hero/layout-05";
import ServiciosArea from "@containers/index/servicios-area";
import Footer from "@layout/footer/layout-01";
import ExperienciaArea from "@containers/index/experiencia-area";
import NosotrosArea from "@containers/index/nosotros-area";
import ContactoArea from "@containers/index/contacto-area";

const IndexPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo title="Eventos Ghupa" />
      <Header />
      <main className="site-wrapper-reveal">
        <HeroArea />
        <ServiciosArea />
        <ExperienciaArea />
        <NosotrosArea />
        <ContactoArea />
      </main>
      <Footer />
    </Layout>
  );
};

export default IndexPage;
