import React from "react";
import { Container, Row, Col } from "@ui/wrapper";
import { useStaticQuery, graphql } from "gatsby";
import SectionTitle from "@ui/section-title";
import Text from "@ui/text";
import Image from "@ui/image";
import {
  NosotrosAreaWrap,
  NosotrosTextBox,
  NosotrosImageBox,
  MainImageBox,
} from "./style";

const NosotrosArea = () => {
  const nosotrosData = useStaticQuery(graphql`
    {
      video: file(
        relativePath: { eq: "nosotros/video-01-image-01-570x350.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }

      foto1: file(
        relativePath: {
          eq: "nosotros/home-processing-video-intro-slider-slide-01-image-02.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }

      foto2: file(
        relativePath: {
          eq: "nosotros/home-processing-video-intro-slider-slide-01-image-03.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }

      foto2: file(
        relativePath: {
          eq: "nosotros/home-processing-video-intro-slider-slide-01-image-03.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }

      foto3: file(
        relativePath: {
          eq: "nosotros/home-processing-video-intro-slider-slide-01-image-04.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      foto4: file(
        relativePath: {
          eq: "nosotros/home-processing-video-intro-slider-slide-01-image-05.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);
  const { video, foto1, foto2, foto3, foto4 } = nosotrosData;

  const data = {
    images: [
      {
        src: video.childImageSharp.gatsbyImageData,
      },
      {
        src: foto1.childImageSharp.gatsbyImageData,
      },
      {
        src: foto2.childImageSharp.gatsbyImageData,
      },
      {
        src: foto3.childImageSharp.gatsbyImageData,
      },
      {
        src: foto4.childImageSharp.gatsbyImageData,
      },
    ],
  };

  return (
    <NosotrosAreaWrap>
      <Container fluid>
        <Row alignItems="center">
          <Col lg={6}>
            <NosotrosTextBox>
              <SectionTitle title="Nosotros" />
              <Text>
                Somos la principal empresa de producción de eventos de Lima,
                ubicada en la zona sur. Eventos Ghupa es una compañía de
                producción de eventos, sonido, escenario e iluminación que
                ofrece audio profesional, sonido en vivo, iluminación de
                escenario, alquiler de equipos para DJ, proyección para las
                industrias del entretenimiento y corporativas desde 2005. Desde
                su fundación hace más de 15 años. Hace, Eventos Ghupa mantiene
                su compromiso de ser un pionero de los eventos. Ya sea que se
                trate de un evento grande, una ceremonia de matrimonio, una
                función de negocios o una fiesta privada, podemos proporcionarle
                un sonido, video, proyección o pantallas que se adapten a sus
                necesidades. Contamos con un inventario de escenario e
                iluminación, junto con un gran stock de monitores de plasma,
                televisores LED, micrófonos inalámbricos y soporte de micrófono
                y parlantes. Cuando combina este enorme inventario con expertos
                diseñadores audiovisuales, de iluminación o de escenografía,
                podemos hacer de su evento una obra maestra imaginativa y
                profesional que todos recordarán.
                <br /> <br />
                ¡Hagamos de su próximo evento una fiesta que nadie olvidará!
                Cada artículo se prueba antes de salir de nuestro almacén para
                garantizar los más altos estándares de calidad. ¡Hagamos de su
                próximo evento una fiesta que nadie olvidará!
              </Text>
            </NosotrosTextBox>
          </Col>
          <Col lg={6}>
            <NosotrosImageBox>
              {data.images.slice(1).map((img, i) => (
                <div
                  className={`animation_image img-${i}`}
                  key={`nosotros-${i}`}
                >
                  <Image src={img.src} alt="Ghupa" />
                </div>
              ))}
              <MainImageBox>
                <Image src={data.images[0].src} alt="Ghupa" />
              </MainImageBox>
            </NosotrosImageBox>
          </Col>
        </Row>
      </Container>
    </NosotrosAreaWrap>
  );
};

export default NosotrosArea;
