import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import { Container, Row, Col } from "@ui/wrapper";
import Heading from "@ui/heading";
import Text from "@ui/text";
import BoxIcon from "@components/box-icon/layout-04";
import Anchor from "@ui/anchor";
import ContactFrom from "@components/forms/contact-form";
import { HeadingType, TextType } from "@utils/types";
import {
  SectionWrap,
  ContactInfoBox,
  ContactFormBox,
  ContactFormTitle,
} from "./style";

const ContactArea = ({ data }) => {
  const contactData = useStaticQuery(graphql`
    query ContactFourQuery {
      file(relativePath: { eq: "bg/contact-section-bg-3.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 626
            height: 982
            placeholder: TRACED_SVG
            formats: WEBP
            quality: 100
          )
        }
      }
    }
  `);
  const bgImage = convertToBgImage(getImage(contactData.file));

  return (
    <SectionWrap {...bgImage}>
      <Container>
        <Row alignItems="center">
          <Col lg={4}>
            <ContactInfoBox>
              <>
                <Anchor display="block" path="tel:+51922371796">
                  <BoxIcon
                    alignItems="center"
                    mt="55px"
                    layout={2}
                    icon="fal fa-phone"
                    heading="¡Llámanos!"
                    title="+51922371796"
                  />
                </Anchor>
                <Anchor
                  display="block"
                  path="mailto:presupuestos@eventosghupa.com"
                >
                  <BoxIcon
                    alignItems="center"
                    mt="55px"
                    layout={2}
                    icon="fal fa-envelope"
                    heading="¡Escríbenos!"
                    title="presupuestos@eventosghupa.com"
                  />
                </Anchor>
              </>
            </ContactInfoBox>
          </Col>
          <Col lg={7} ml="auto">
            <ContactFormBox>
              <ContactFormTitle>
                <Heading as="h3" mb="10px">
                  Contáctanos
                </Heading>
                <Text>Pregúntanos sobre paquetes de equipo con descuento.</Text>
              </ContactFormTitle>
              <ContactFrom />
            </ContactFormBox>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

ContactArea.propTypes = {
  data: PropTypes.shape({
    headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
    texts: PropTypes.arrayOf(PropTypes.shape(TextType)),
    contact: PropTypes.shape({
      phone: PropTypes.string,
      email: PropTypes.string,
    }),
  }),
};

export default ContactArea;
