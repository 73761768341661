import styled, { device } from "@styled";
import BackgroundImage from "gatsby-background-image";

export const SectionWrap = styled(BackgroundImage)`
  background-color: #eff2f6;
  padding-block: 67px;
  ${device.large} {
    padding-block: 100px;
  }
`;
