import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import { Container, Row, Col } from "@ui/wrapper";
import Heading from "@ui/heading";
import Text from "@ui/text";
import { SectionWrap } from "./style";

const ExperienciaArea = () => {
  const experienciaData = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "bg/experiencia.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: TRACED_SVG
            formats: WEBP
            quality: 100
          )
        }
      }
    }
  `);

  const bg_image = convertToBgImage(getImage(experienciaData.file));

  return (
    <SectionWrap {...bg_image}>
      <Container>
        <Row alignItems="center">
          <Col lg={7}>
            <Heading as="h1">Experiencia</Heading>
            <Text color="white">
              Ya sea el productor de un evento corporativo épico o el anfitrión
              de una ocasión especial, nuestro equipo lo tiene cubierto. Con más
              de 15 años en el negocio, tenemos las habilidades, el equipo y la
              experiencia para asegurar que su evento se desarrolle a la
              perfección. Para los productores de eventos, tenemos una amplia
              gama de referencias profesionales, equipos y capacidades.
              Agradecemos la oportunidad de presentar ofertas competitivas para
              su evento. Si es la primera vez que contratas nuestros servicios,
              ¡te daremos opciones y una lista de que necesitarás para que tu
              evento sea un éxito! ​
            </Text>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default ExperienciaArea;
